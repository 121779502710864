import React, { useEffect, useState } from 'react';
import IframePlayer from 'src/components/IframePlayer';
import ChatRoom from 'src/components/Chat';
import { SpeakerElement } from 'src/components/SpeakersGrid';
import Vimeo from 'src/components/Vimeo';
import { urlWebsite } from 'src/config';
import { useConfiguration } from 'src/hooks/Configuration';
import { useLanguage } from 'src/hooks/Language';
import { ContainerClassroom } from './styles';
import RoomConfiguration from './modules/RoomConfigurations';
import CounterCardRoomSocket from 'src/pages/DashboardPages/core/components/CounterCardRoomSocket';
import { useSocket } from 'src/hooks/Socket';
import { FiList, FiMenu } from 'react-icons/fi';
import { useModal } from 'src/hooks/Modal';
import { SidebarContainer } from '../SidebarClassroom/styles';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { RoomChangeContainer } from './modules/RoomChange';

const ContentClassRoom: React.FC<Record<string, any>> = React.memo(({ courseHook }) => {
    const { configuration } = useConfiguration();
    const { translate, language } = useLanguage();
    const [currentLanguage, setCurrentLanguage] = useState('ptBr');
    const { content, speakers, sidebarContent, selectContent, backToStart, nextContent, prevContent } = courseHook;
    const { socket } = useSocket();
    const { addModal, removeModal } = useModal();

    const showModal = () => {

        addModal({
            title: '', theme: 'none', key: 'sidebar', content: <SidebarContainer className='column  ' style={{ width: '100%', position: 'relative' }}><div className='full-width mobileOnly fixed fadeIn' style={{ maxHeight: '100vh', background: '#333' }}>
                <h2 onClick={() => { removeModal('sidebar'); window?.scrollTo(0, 0) }} className='fadeIn'>Fechar</h2>
                {sidebarContent?.map(sidebarModule => {
                    return <div className='full-width  column '>
                        <h2 className='fadeIn'>{sidebarModule?.title}</h2>
                        <div>
                            {sidebarModule?.content?.map(contentItem => {

                                return <nav style={{ color: content?._id === contentItem?._id ? '#333' : '#fff' }} className={`fadeIn ${content?._id === contentItem?._id ? 'activeSidebarMenu' : ''}`} onClick={() => {
                                    selectContent(contentItem?._id);

                                    removeModal('sidebar');
                                    window?.scrollTo(0, 0);
                                }}>{contentItem?.[`title_${language}`] ? contentItem?.[`title_${language}`] : contentItem?.title}</nav>


                            })}
                        </div>


                    </div>

                })}

            </div></SidebarContainer>
        })

    }


    useEffect(() => {
        const ping = setInterval(() => {

            if (content?._id) {
                socket?.emit('SetAttendance', {
                    project_id: configuration?.url,
                    event_id: configuration?.current_event_id_response?.url || '',
                    room: content._id || '0',
                });
            }

        }, 120000);
        if (content?._id) {



            socket?.emit('SetAttendance', {
                project_id: configuration?.url,
                event_id: configuration?.current_event_id_response?.url || '',
                room: content._id || '0',
            });
        }


        return () => clearInterval(ping);
    }, [content]);

    return <ContainerClassroom className='column full-width pad max-1200' style={{ marginTop: '10px' }} >
        <div className='row full-width max-1200 classroom-header' style={{ justifyContent: 'space-between' }}>

            <nav className='row navClassRoom   fadeIn' style={{ width: '100%', margin: ' 10px', justifyContent: 'space-between' }}>
                <h2 className='row gap-xs' style={{ color: '#fff', cursor: 'pointer', padding: '5px 10px', width: '100px', background: 'rgba(0,0,0,0.8)', borderRadius: '10px', }} onClick={() => { backToStart() }}><FaChevronLeft style={{ fontSize: '18px' }} /> Voltar</h2>

                <CounterCardRoomSocket room={content._id} color="#fff" />
                <FiList onClick={() => showModal()} className='mobileOnly' style={{ fontSize: '32px', color: '#fff', cursor: 'pointer' }} />

            </nav>

            <h2 className='fadeIn' >{content?.[`title_${language}`] ? content?.[`title_${language}`] : content?.title}</h2>

            <div className='row gap-xs' style={{ alignItems: 'center' }}>
                <h2 className='row' onClick={() => setCurrentLanguage('ptBr')} style={{ background: '#fff', borderRadius: '5px', fontSize: '12px', cursor: 'pointer', color: currentLanguage === 'ptBr' ? 'rgb(167, 28, 32)' : '#333', minWidth: '30px', height: '30px', }}>POR</h2>
                {content?.languages?.en ? <h2 className='row' onClick={() => setCurrentLanguage('en')} style={{ background: '#fff', borderRadius: '5px', fontSize: '12px', cursor: 'pointer', color: currentLanguage === 'en' ? 'rgb(167, 28, 32)' : '#333', minWidth: '30px', height: '30px', }}>ENG</h2> : <></>}
                {content?.languages?.es ? <h2 className='row' onClick={() => setCurrentLanguage('es')} style={{ background: '#fff', borderRadius: '5px', fontSize: '12px', cursor: 'pointer', color: currentLanguage === 'es' ? 'rgb(167, 28, 32)' : '#333', minWidth: '30px', height: '30px', }}>ESP</h2> : <></>}

            </div>

        </div>
        <div className='max-1200 fadeIn ' style={{ width: '100%' }}>
            {content?.video_type_id === 'vimeo' ? <Vimeo id={currentLanguage === 'ptBr' ? content?.video_id : content?.languages?.[currentLanguage] || content?.video_id} /> : <IframePlayer id={currentLanguage === 'ptBr' ? content?.video_id : content?.languages?.[currentLanguage] || content?.video_id} />}
        </div>

        <div className='row pad' style={{ justifyContent: 'space-between', width: '100%' }}>
            {prevContent ? <h2 className='row gap-xs' style={{ color: '#fff', cursor: 'pointer', padding: '5px 10px', width: '150px', background: 'rgba(0,0,0,0.8)', borderRadius: '10px', }} onClick={() => { selectContent(prevContent) }}><FaChevronLeft style={{ fontSize: '18px', minWidth: '20px' }} /> Anterior</h2>
                : <div />}
            {nextContent ? <h2 className='row gap-xs' style={{ color: '#fff', cursor: 'pointer', padding: '5px 10px', width: '150px', background: 'rgba(0,0,0,0.8)', borderRadius: '10px', }} onClick={() => { selectContent(nextContent) }}> Próximo <FaChevronRight style={{ fontSize: '18px', minWidth: '20px' }} /></h2> : <div />}
        </div>

        <p>{content?.description}</p>
        {content?.speakers?.length > 0 ? <>
            <h2 className='fadeIn max-1000' style={{ marginTop: '30px' }}>{translate('Palestrantes')}</h2>

            <div className='row flex-wrap max-1200' style={{ gap: '10px', flexWrap: 'wrap', width: '100%' }}>
                {content?.speakers?.map((speakerID) => {

                    const speaker = speakers?.find(i => i?._id === speakerID);


                    return speaker ? <SpeakerElement speaker={speaker} theme={configuration?.current_event_id_response?.theme} /> : <></>





                })}
            </div>
        </> : <></>}
        <RoomChangeContainer courseHook={courseHook} />
    </ContainerClassroom>



});

export default ContentClassRoom;